import axiosClient from "./axiosClient";

const getCompetitionSingle = async (competitionId) => {
  return await axiosClient.get(
    `competitions/single?competition_id=${competitionId}`
  );
};

const searchCompetitions = async (params) => {
  return await axiosClient.get("competitions/search", { params });
};

export { getCompetitionSingle, searchCompetitions };

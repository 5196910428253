import { Button, Layout, Menu } from "antd";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { colors } from "./constants";
import { useEffect, useState } from "react";
import CompetitionChoice from "./components/CompetitionChoice";
import { useQuery } from "react-query";
import { getCompetitionSingle } from "./api/competitions";
import MatchReport from "./components/MatchReport";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import LiveMatches from "./components/LiveMatches";
import Login from "./components/Login";
import Previous from "./components/Previous";
import { getAdminSettings } from "./api/users";

const { Sider } = Layout;

function App() {
  const [isAdminLoggedIn, setIsAdminLoggedIn] = useState(false);
  const [competitionId, setCompetitionId] = useState(271);
  const [competition, setCompetition] = useState(null);
  const [collapsed, setCollapsed] = useState(false);
  const [isCompetitionModalVisible, setIsCompetitionModalVisible] =
    useState(false);

  const [matchId, setMatchId] = useState(383364);

  const onCompetitionChoice = (competitionId) => {
    setIsCompetitionModalVisible(false);
    setCompetitionId(competitionId);
  };

  const getCompetition = async () => {
    const result = await getCompetitionSingle(competitionId);
    const competitionData = result.data.data;
    setCompetition(competitionData);
  };

  useQuery({
    queryKey: ["competition", competitionId],
    queryFn: getCompetition,
    staleTime: Infinity,
  });

  const getCompetitionTitle = () => {
    let association = "";

    if (competition.countries.length > 0) {
      association = competition.countries[0].name;
    }

    if (competition.federations.length > 0) {
      association = competition.federations[0].name;
    }

    return (
      <>
        {association}: {competition.name}
      </>
    );
  };

  const onMatchChoice = (matchId) => {
    setMatchId(matchId);
  };

  useEffect(() => {
    const checkIsLoggedIn = async () => {
      if (localStorage.getItem("authToken")) {
        try {
          const data = await getAdminSettings();
          setIsAdminLoggedIn(data.length > 0);
        } catch (e) {
          console.log("Could not authentican admin: " + e.toString());
        }
      }
    };

    checkIsLoggedIn();
  }, []);

  return (
    <Router basename="/">
      {isAdminLoggedIn ? (
        <Layout>
          <Sider
            style={{ backgroundColor: colors.primary }}
            collapsible
            collapsed={collapsed}
            onCollapse={setCollapsed}
          >
            <img
              src={require("./img/live-score-api-logo.png")}
              alt="Live Score API Logo"
              style={{ width: "80%", paddingTop: "10%", paddingLeft: "10%" }}
            />
            <Button
              type="text"
              style={{ color: colors.thirthiary }}
              onClick={() => setIsCompetitionModalVisible(true)}
            >
              <b>
                {competition
                  ? getCompetitionTitle()
                  : collapsed
                  ? "Choose"
                  : "Choose competition"}
              </b>
            </Button>
            <Menu style={{ backgroundColor: colors.primary, color: "white" }}>
              <Menu.Item key="1">
                <span>Deshboard</span>
                <Link to="/" />
              </Menu.Item>
              <Menu.Item key="2">
                <span>Report</span>
                <Link to="/report" />
              </Menu.Item>
              <Menu.Item key="3">
                <span>Previous</span>
                <Link to="/previous" />
              </Menu.Item>
            </Menu>
          </Sider>
          <Layout>
            <Routes>
              <Route
                path="/"
                element={
                  <LiveMatches
                    competitionId={competitionId}
                    onMatchChoice={onMatchChoice}
                    matchId={matchId}
                  />
                }
              />
              <Route
                path="/report"
                element={<MatchReport matchId={matchId} />}
              />
              <Route
                path="/report/new"
                element={<MatchReport matchId={matchId} />}
              />
              <Route
                path="/report/:commentaryEventId"
                element={<MatchReport matchId={matchId} />}
              />
              <Route path="/login" element={<Login />} />
              <Route
                path="/previous"
                element={
                  <Previous matchId={matchId} onMatchChoice={onMatchChoice} />
                }
              />
            </Routes>
          </Layout>
          {isCompetitionModalVisible ? (
            <CompetitionChoice
              onCompetitionChoice={onCompetitionChoice}
              onCancel={() => setIsCompetitionModalVisible(false)}
            />
          ) : null}
        </Layout>
      ) : (
        <Layout>
          <Login onLogin={setIsAdminLoggedIn} />
        </Layout>
      )}
    </Router>
  );
}

export default App;

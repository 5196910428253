import md5 from "md5";

const getTeamLogoUrl = (teamId) => {
  let d = new Date();
  let mySqlTimestamp = new Date(d.getFullYear(), d.getMonth() + 1)
    .toISOString()
    .slice(0, 7)
    .replace("-", "");

  return `https://cdn.live-score-api.com/teams/${md5(
    mySqlTimestamp + "01" + teamId + ".png"
  )}.png`;
};

export { getTeamLogoUrl };

import { Divider, Table, Spin, Button } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { getPreviousMatches } from "../api/events";
import { useQuery } from "react-query";

const formatDateString = (timestamp) => {
  const date = new Date(timestamp * 1000);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString();
  return `${day}.${month}.${year}`;
};

const formatCompetition = (competition) => {
  if (!competition) return "";
  let association = "";
  if (competition.countries && competition.countries.length > 0) {
    association = competition.countries[0].name;
  } else if (competition.federations && competition.federations.length > 0) {
    association = competition.federations[0].name;
  }
  return `${association} :: ${competition.name}`;
};

const loadPreviousMatches = async () => {
  const response = await getPreviousMatches();
  return response.data.data.map((item) => ({
    ...item,
    date: formatDateString(item.date),
    competition: formatCompetition(item.competition),
    score: `${item.score} (${item.ht_score})`,
  }));
};

const Previous = ({ matchId, onMatchChoice }) => {
  const { data, isLoading } = useQuery({
    queryKey: "previousMatches",
    queryFn: loadPreviousMatches,
    staleTime: 300000,
  });

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      align: "left",
    },
    {
      title: "Home",
      dataIndex: "home_name",
      key: "home_name",
      align: "right",
    },
    {
      title: "Away",
      dataIndex: "away_name",
      key: "away_name",
    },
    {
      title: "Competition",
      dataIndex: "competition",
      key: "competition",
      align: "left",
    },
    {
      title: "Score",
      dataIndex: "score",
      key: "score",
      align: "center",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button
          type="primary"
          onClick={() => {
            onMatchChoice(record.id);
          }}
          disabled={record.id === matchId}
        >
          {record.id === matchId ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <CheckOutlined />
              <span>Chosen</span>
            </div>
          ) : (
            "Choose"
          )}
        </Button>
      ),
      align: "center",
    },
  ];

  return (
    <div>
      <h1>Previous commentary matches</h1>
      <Divider />
      <div style={{ display: "flex", justifyContent: "center" }}>
        {isLoading && <Spin size="large" />}
      </div>
      {!isLoading && (
        <Table
          columns={columns}
          dataSource={data}
          pagination={{ pageSize: 30, showSizeChanger: false }}
        />
      )}
    </div>
  );
};

export default Previous;

import { getMatchesLive } from "./../api/matches";
import { useQuery } from "react-query";
import { Alert, Col, Divider, Row, Table, Typography, Button } from "antd";
import { getHealthReport } from "../api/reports";
import { CheckOutlined } from "@ant-design/icons";

const LiveMatches = ({ onMatchChoice, competitionId, matchId }) => {
  const loadLiveMatches = async () => {
    const result = await getMatchesLive(competitionId);
    return result.data.data;
  };

  const loadHealth = async () => {
    const result = await getHealthReport();
    return result.data.data;
  };

  const { data } = useQuery(
    ["live-matches-all", competitionId],
    loadLiveMatches,
    { staleTime: 60 }
  );

  const { isLoading, data: health } = useQuery("health", loadHealth);

  const columns = [
    {
      title: "Home",
      dataIndex: "home_name",
      key: "home_name",
      align: "right",
    },
    {
      title: "Away",
      dataIndex: "away_name",
      key: "away_name",
    },
    {
      title: "Score",
      dataIndex: "score",
      key: "score",
      align: "center",
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      align: "center",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button
          type="primary"
          onClick={() => {
            onMatchChoice(record.id);
          }}
          disabled={record.id === matchId}
        >
          {record.id === matchId ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <CheckOutlined />
              <span>Chosen</span>
            </div>
          ) : (
            "Choose"
          )}
        </Button>
      ),
      align: "center",
    },
  ];

  let healthAlerts = [];
  if (!isLoading) {
    if (health.ok) {
      healthAlerts.push("All checks passed");
    } else {
      healthAlerts = health.errors;
    }
  }

  return (
    <>
      {/* <Row gutter={16}>
        <Col span={22} offset={1}>
          {!isLoading &&
            healthAlerts.map((item) => (
              <Alert
                key={item}
                type={health.ok ? "success" : "error"}
                message={item}
              />
            ))}
          <Divider orientation="left" />
        </Col>
      </Row> */}
      <Row gutter={16}>
        <Col span={22} offset={1}>
          <Typography.Title level={1} style={{ margin: 0 }}>
            Live Matches in competition ({competitionId})
          </Typography.Title>
          <br />
          <Table
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  onMatchChoice(record.id);
                },
              };
            }}
            dataSource={data}
            columns={columns}
            rowKey="id"
          />
          <br />
        </Col>
      </Row>
    </>
  );
};

export default LiveMatches;

import AdditionalTimeIcon from "./../img/event-icons/ADDITIONAL_TIME.svg";
import ContainsBallIcon from "./../img/event-icons/CONTAINS_BALL.svg";

import CornerKickAwardedIcon from "./../img/event-icons/CORNER_KICK_AWARDED.svg";
import CornerKickTakenIcon from "./../img/event-icons/CORNER_KICK_TAKEN.svg";
import CrossbarHitIcon from "./../img/event-icons/CROSSBAR_HIT.svg";
import DrawIcon from "./../img/event-icons/DRAW.svg";
import ExtraTimeHalfTimeIcon from "./../img/event-icons/EXTRA_TIME_HALF_TIME.svg";
import ExtraTimeSecondHalfStartIcon from "./../img/event-icons/EXTRA_TIME_SECOND_HALF_START.svg";
import ExtraTimeStartIcon from "./../img/event-icons/EXTRA_TIME_START.svg";
import ExtraTimeOverIcon from "./../img/event-icons/EXTRA_TIME_OVER.svg";
import FirstHalfOverIcon from "./../img/event-icons/FIRST_HALF_OVER.svg";

import FoulCommittedIcon from "./../img/event-icons/FOUL_COMMITTED.svg";
import ShotOnTargetIcon from "./../img/event-icons/SHOT_ON_TARGET.svg";
import ShotOffTargetIcon from "./../img/event-icons/SHOT_OFF_TARGET.svg";
import ShotBlockedIcon from "./../img/event-icons/SHOT_BLOCKED.svg";
import SecondYellowCardIcon from "./../img/event-icons/YELLOW_RED_CARD.svg";
import SecondHalfStartIcon from "./../img/event-icons/SECOND_HALF_START.svg";
import SecondHalfOverIcon from "./../img/event-icons/SECOND_HALF_OVER.svg";
import RedCardIcon from "./../img/event-icons/RED_CARD.svg";
import QualifiesNextRoundIcon from "./../img/event-icons/QUALIFIES_NEXT_ROUND.svg";
import PostHitIcon from "./../img/event-icons/POST_HIT.svg";
import PenaltyShootoutTakerIcon from "./../img/event-icons/PENALTY_SHOOTOUT_TAKER.svg";
import PenaltyShootoutEndIcon from "./../img/event-icons/PENALTY_SHOOTOUT_END.svg";
import PenaltyShootoutSavedIcon from "./../img/event-icons/PENALTY_SHOOTOUT_SAVED.svg";
import PenaltyShootoutMissIcon from "./../img/event-icons/PENALTY_SHOOTOUT_MISS.svg";
import PenaltyShootoutGoalIcon from "./../img/event-icons/PENALTY_SHOOTOUT_GOAL.svg";
import PenaltyShootoutStartIcon from "./../img/event-icons/PENALTY_SHOOTOUT_START.svg";
import PenaltyMissIcon from "./../img/event-icons/MISSED_PENALTY.svg";
import PenaltyAwardedIcon from "./../img/event-icons/PENALTY_AWARDED.svg";
import OwnGoalIcon from "./../img/event-icons/OWN_GOAL.svg";
import OffsideIcon from "./../img/event-icons/OFFSIDE.svg";
import MatchStartIcon from "./../img/event-icons/MATCH_START.svg";
import MatchEndsIcon from "./../img/event-icons/MATCH_ENDS.svg";
import KickOffIcon from "./../img/event-icons/KICK_OFF.svg";
import GoalPenaltyIcon from "./../img/event-icons/GOAL_PENALTY.svg";
import GoalkeeperSaveIcon from "./../img/event-icons/GOALKEEPER_SAVE.svg";
import GoalIcon from "./../img/event-icons/GOAL.svg";
import FreeKickTakenIcon from "./../img/event-icons/FREE_KICK_TAKEN.svg";

import SubstitutionIcon from "./../img/event-icons/SUBSTITUTION.svg";
import ThrowInAwardedIcon from "./../img/event-icons/THROW_IN_AWARDED.svg";
import ThrowInTakenIcon from "./../img/event-icons/THROW_IN_TAKEN.svg";
import TreatmentIcon from "./../img/event-icons/TREATMENT.svg";
import VarGoalReviewIcon from "./../img/event-icons/VAR_GOAL_REVIEW.svg";
import VarPenaltyReviewIcon from "./../img/event-icons/VAR_PENALTY_REVIEW.svg";
import VarRedCardReviewIcon from "./../img/event-icons/VAR_RED_CARD_REVIEW.svg";
import VarReviewOverIcon from "./../img/event-icons/VAR_REVIEW_OVER.svg";
import WastingTimeIcon from "./../img/event-icons/WASTING_TIME.svg";
import WaterBreakIcon from "./../img/event-icons/WATER_BREAK.svg";
import WinnerIcon from "./../img/event-icons/WINNER.svg";
import YellowCardIcon from "./../img/event-icons/YELLOW_CARD.svg";

import GoalKickIcon from "./../img/event-icons/GOAL_KICK.svg";
import GoalKickTakenIcon from "./../img/event-icons/GOAL_KICK_TAKEN.svg";
import PlayerWarnedIcon from "./../img/event-icons/PLAYER_WARNED.svg";
import YellowCardCanceledIcon from "./../img/event-icons/YELLOW_CARD_CANCELED.svg";
import RedCardCanceledIcon from "./../img/event-icons/RED_CARD_CANCELED.svg";
import MatchStoppedInjuryIcon from "./../img/event-icons/MATCH_STOPPED_INJURY.svg";

const getIconComponentFromEventTypeId = (eventTypeId) => {
  const map = {
    ADDITIONAL_TIME: AdditionalTimeIcon,
    CONTAINS_BALL: ContainsBallIcon,
    CORNER_KICK_AWARDED: CornerKickAwardedIcon,
    CORNER_KICK_TAKEN: CornerKickTakenIcon,
    CROSSBAR_HIT: CrossbarHitIcon,
    DRAW: DrawIcon,
    EXTRA_TIME_HALF_TIME: ExtraTimeHalfTimeIcon,
    EXTRA_TIME_SECOND_HALF_START: ExtraTimeSecondHalfStartIcon,
    EXTRA_TIME_START: ExtraTimeStartIcon,
    EXTRA_TIME_OVER: ExtraTimeOverIcon,
    FIRST_HALF_OVER: FirstHalfOverIcon,
    FOUL_COMMITTED: FoulCommittedIcon,
    SHOT_ON_TARGET: ShotOnTargetIcon,
    SHOT_OFF_TARGET: ShotOffTargetIcon,
    SHOT_BLOCKED: ShotBlockedIcon,
    YELLOW_RED_CARD: SecondYellowCardIcon,
    SECOND_HALF_START: SecondHalfStartIcon,
    SECOND_HALF_OVER: SecondHalfOverIcon,
    RED_CARD: RedCardIcon,
    QUALIFIES_NEXT_ROUND: QualifiesNextRoundIcon,
    POST_HIT: PostHitIcon,
    MISSED_PENALTY: PenaltyMissIcon,
    PENALTY_AWARDED: PenaltyAwardedIcon,
    OWN_GOAL: OwnGoalIcon,
    OFFSIDE: OffsideIcon,
    MATCH_START: MatchStartIcon,
    MATCH_ENDS: MatchEndsIcon,
    KICK_OFF: KickOffIcon,
    GOAL_PENALTY: GoalPenaltyIcon,
    GOALKEEPER_SAVE: GoalkeeperSaveIcon,
    GOAL: GoalIcon,
    FREE_KICK_TAKEN: FreeKickTakenIcon,
    SUBSTITUTION: SubstitutionIcon,
    THROW_IN_AWARDED: ThrowInAwardedIcon,
    THROW_IN_TAKEN: ThrowInTakenIcon,
    TREATMENT: TreatmentIcon,
    VAR_GOAL_REVIEW: VarGoalReviewIcon,
    VAR_PENALTY_REVIEW: VarPenaltyReviewIcon,
    VAR_RED_CARD_REVIEW: VarRedCardReviewIcon,
    VAR_REVIEW_OVER: VarReviewOverIcon,
    WASTING_TIME: WastingTimeIcon,
    WATER_BREAK: WaterBreakIcon,
    WINNER: WinnerIcon,
    YELLOW_CARD: YellowCardIcon,
    GOAL_KICK: GoalKickIcon,
    GOAL_KICK_TAKEN: GoalKickTakenIcon,
    PLAYER_WARNED: PlayerWarnedIcon,
    YELLOW_CARD_CANCELED: YellowCardCanceledIcon,
    RED_CARD_CANCELED: RedCardCanceledIcon,
    MATCH_STOPPED_INJURY: MatchStoppedInjuryIcon,
    PENALTY_SHOOTOUT_START: PenaltyShootoutStartIcon,
    PENALTY_SHOOTOUT_TAKER: PenaltyShootoutTakerIcon,
    PENALTY_SHOOTOUT_END: PenaltyShootoutEndIcon,
    PENALTY_SHOOTOUT_SAVED: PenaltyShootoutSavedIcon,
    PENALTY_SHOOTOUT_MISS: PenaltyShootoutMissIcon,
    PENALTY_SHOOTOUT_GOAL: PenaltyShootoutGoalIcon,
  };

  return map[eventTypeId];
};

export { getIconComponentFromEventTypeId };

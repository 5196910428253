import axiosClient from "./axiosClient";

const getMatchesSingle = async (matchId) => {
  return await axiosClient.get(`matches/single?match_id=${matchId}&lineups=1`);
};

const getMatchesLive = async (competitionId) => {
  return await axiosClient.get(
    `/live-matches/all?competition_id=${competitionId}`
  );
};

const getMatchScore = async (matchId) => {
  return await axiosClient.get(`/live-matches/score?match_id=${matchId}`);
};

const saveMatchScore = async (payload) => {
  return await axiosClient.post(`/live-matches/score`, payload);
};

export { getMatchesSingle, getMatchesLive, getMatchScore, saveMatchScore };

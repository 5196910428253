import axios from "axios";

const axiosClient = axios.create({
  baseURL: "https://new.livescore-api.com/v2",
  // baseURL: "http://livescore-api.api/v2",
  // baseURL: "https://staging.livescore-api.com/v2",
  // baseURL: "http://staging.test-ls.com/v2",
});

axiosClient.interceptors.request.use(
  (config) => {
    config.headers["x-auth-token"] = localStorage.getItem("authToken");
    config.headers["x-system"] = "commentary";
    return config;
  },
  (error) => Promise.reject(error)
);

export default axiosClient;

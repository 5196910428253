import { useQuery } from "react-query";
import { getMatchClock } from "./../api/events";
import { Input } from "antd";

function DisplayMatchClock({ minute, second, matchSecond, onChange }) {
  if (onChange) {
    return (
      <div>
        <Input
          value={minute}
          style={{ width: 70 }}
          onChange={(e) => {
            onChange(e.target.value, second, matchSecond);
          }}
          allowClear
          status={!!minute ? "" : "error"}
        />
        <Input
          value={second}
          style={{ width: 70 }}
          onChange={(e) => {
            onChange(minute, e.target.value, matchSecond);
          }}
          allowClear
          status={!!second ? "" : "error"}
        />
        <Input
          value={matchSecond}
          style={{ width: 80 }}
          onChange={(e) => {
            onChange(minute, second, e.target.value);
          }}
          allowClear
          status={!!matchSecond ? "" : "error"}
        />
      </div>
    );
  }

  return (
    <div>
      {minute}':{second}'' ({matchSecond})
    </div>
  );
}

function Clock({ matchId }) {
  const getClock = async () => {
    const result = await getMatchClock(matchId);
    return result.data.data;
  };

  const { data } = useQuery("match-clock", getClock, {
    refetchInterval: 10000,
  });

  if (!data) {
    return null;
  }

  return (
    <DisplayMatchClock
      minute={data.minute}
      second={data.second}
      matchSecond={data.match_second}
    />
  );
}

export { Clock as default, DisplayMatchClock };

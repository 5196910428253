const chainedEvents = {
  FOUL_COMMITTED: [
    "FREE_KICK_TAKEN",
    "YELLOW_CARD",
    "YELLOW_RED_CARD",
    "RED_CARD",
    "PENALTY_AWARDED",
    "TREATMENT",
  ],
  MATCH_ENDS: ["WINNER", "DRAW", "QUALIFIES_NEXT_ROUND"],
  WINNER: ["QUALIFIES_NEXT_ROUND"],
  DRAW: ["QUALIFIES_NEXT_ROUND"],
  QUALIFIES_NEXT_ROUND: ["QUALIFIES_NEXT_ROUND"],
  OFFSIDE: ["FREE_KICK_TAKEN"],
  CORNER_KICK_AWARDED: ["CORNER_KICK_TAKEN"],
  THROW_IN_AWARDED: ["THROW_IN_TAKEN"],
  GOAL_KICK: ["GOAL_KICK_TAKEN"],
  SHOT_ON_TARGET: [
    "SHOT_BLOCKED",
    "GOALKEEPER_SAVE",
    "CROSSBAR_HIT",
    "POST_HIT",
  ],
  SHOT_OFF_TARGET: [
    "SHOT_BLOCKED",
    "GOALKEEPER_SAVE",
    "GOAL_KICK",
    "CORNER_KICK_AWARDED",
  ],
  GOAL: ["KICK_OFF"],
  OWN_GOAL: ["KICK_OFF"],
  GOAL_PENALTY: ["KICK_OFF"],
  PENALTY_AWARDED: ["GOAL_PENALTY", "MISSED_PENALTY"],
  TREATMENT: ["MATCH_STOPPED_INJURY"],
  VAR_RED_CARD_REVIEW: ["VAR_REVIEW_OVER"],
  VAR_GOAL_REVIEW: ["VAR_REVIEW_OVER"],
  VAR_PENALTY_REVIEW: ["VAR_REVIEW_OVER"],
  FIRST_HALF_OVER: ["SECOND_HALF_START", "SUBSTITUTION"],
  SECOND_HALF_OVER: ["MATCH_ENDS", "EXTRA_TIME_START", "SUBSTITUTION"],
  EXTRA_TIME_HALF_TIME: ["EXTRA_TIME_SECOND_HALF_START", "SUBSTITUTION"],
  EXTRA_TIME_OVER: ["MATCH_ENDS", "PENALTY_SHOOTOUT_START"],
  PENALTY_SHOOTOUT_END: ["MATCH_ENDS"],
  FREE_KICK_TAKEN: ["SHOT_ON_TARGET", "SHOT_OFF_TARGET", "SHOT_BLOCKED"],
  VAR_REVIEW_OVER: [
    "YELLOW_CARD_CANCELED",
    "RED_CARD_CANCELED",
    "PENALTY_AWARDED",
  ],
  CORNER_KICK_TAKEN: [
    "SHOT_ON_TARGET",
    "SHOT_OFF_TARGET",
    "SHOT_BLOCKED",
    "FOUL_COMMITTED",
  ],
  SHOT_BLOCKED: [
    "SHOT_ON_TARGET",
    "SHOT_OFF_TARGET",
    "GOAL_KICK",
    "CORNER_KICK_AWARDED",
    "CROSSBAR_HIT",
    "POST_HIT",
  ],
  GOALKEEPER_SAVE: [
    "SHOT_ON_TARGET",
    "SHOT_OFF_TARGET",
    "GOAL_KICK",
    "CORNER_KICK_AWARDED",
    "CROSSBAR_HIT",
    "POST_HIT",
  ],
  MISSED_PENALTY: ["GOAL_KICK", "GOALKEEPER_SAVE"],
  YELLOW_CARD: ["FREE_KICK_TAKEN"],
  YELLOW_RED_CARD: ["FREE_KICK_TAKEN"],
  RED_CARD: ["FREE_KICK_TAKEN"],
};

export { chainedEvents };

import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { Col, Modal, Timeline, Row, Input, Popover } from "antd";
import {
  getMatchCommentaryTimeline,
  getSingleEventType,
  getEventTypes,
} from "./../api/events";
import Event from "./Event";
import TeamLogo from "./TeamLogo";

import { getIconComponentFromEventTypeId } from "./Icon";
import Map from "./Map";
import { useParams } from "react-router-dom";

const { Item } = Timeline;

function MatchTimeline({ matchId, matchData, onFilter, clearTimelineFilter }) {
  const [eventCommntary, setEventCommentary] = useState({});
  const [isEventModalVisible, setIsEventModalVisible] = useState(false);
  const [chosenEvent, setChosenEvent] = useState({});
  const [timelineFilter, setTimelineFilter] = useState(null);
  const [matchSecondFilter, setMatchSecondFilter] = useState(null);

  const getMatchTimeline = async () => {
    const result = await getMatchCommentaryTimeline(matchId);
    return result.data.data;
  };

  const { data } = useQuery(["timeline", matchId], getMatchTimeline, {
    cacheTime: Infinity,
    staleTime: Infinity,
  });

  let { commentaryEventId } = useParams();
  useEffect(() => {
    for (let i = 0; i < data?.length; ++i) {
      if (data[i].id == commentaryEventId) {
        onClick(data[i]);
      }
    }
  }, [commentaryEventId, data]);

  const onClick = async (event) => {
    const result = await getSingleEventType(event.event_type);
    setChosenEvent(result.data.data);
    setEventCommentary(event);
    setIsEventModalVisible(true);
  };

  const renderEvenLeftSideLabel = (item) => {
    let minutes = `${item.minute}' ${item.second}''`;
    const maxLength = 12;

    const diff = maxLength - minutes.length;
    for (let i = 0; i < diff; ++i) {
      minutes = <>&nbsp;{minutes}</>;
    }

    return (
      <span style={{ fontFamily: "Courier" }}>
        {item.player2_id ? (
          <span style={{ fontSize: "1rem" }}>&nbsp;&#128085;</span>
        ) : (
          <span style={{ fontSize: "1rem" }}>&nbsp;&nbsp;</span>
        )}
        {item.player_id ? (
          <span style={{ fontSize: "1rem" }}>&nbsp;&#128085;</span>
        ) : (
          <span style={{ fontSize: "1rem" }}>&nbsp;&nbsp;</span>
        )}
        {item.team_id ? (
          <TeamLogo teamId={item.team_id} />
        ) : (
          <>&nbsp;&nbsp;&nbsp;</>
        )}
        {minutes}
      </span>
    );
  };

  const { data: types } = useQuery("types", getEventTypes, {
    refetchOnWindowFocus: false,
  });

  const buildFilters = (timeline) => {
    let filters = {};

    for (let i = 0; i < timeline.length; ++i) {
      const event = timeline[i];
      if (Object.keys(filters).includes(event.event_type)) {
        filters[event.event_type] = filters[event.event_type] + 1;
      } else {
        filters[event.event_type] = 1;
      }
    }

    return filters;
  };

  const toggleTimelineFilter = (newFilter) => {
    if (timelineFilter === newFilter) {
      setTimelineFilter(null);
    } else {
      onFilter();
      setTimelineFilter(newFilter);
    }
  };

  useEffect(() => {
    if (clearTimelineFilter && timelineFilter) {
      setTimelineFilter(null);
    }
  }, [clearTimelineFilter]);

  if (data) {
    const filters = buildFilters(data);
    return (
      <>
        <Row>
          <Col offset={13}>
            <Input
              style={{ width: 80 }}
              onChange={(e) => setMatchSecondFilter(e.target.value)}
              allowClear
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col span={6}>
            <ul style={{ listStyle: "none" }}>
              {timelineFilter ? (
                <li
                  key="All"
                  onClick={() => toggleTimelineFilter(timelineFilter)}
                  style={{ cursor: "pointer" }}
                >
                  Show all
                </li>
              ) : null}

              {filters
                ? Object.keys(filters).map((eventType) => (
                    <li
                      key={eventType}
                      onClick={() => toggleTimelineFilter(eventType)}
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        style={{ height: "20px" }}
                        src={getIconComponentFromEventTypeId(eventType)}
                        alt=""
                      />{" "}
                      ({filters[eventType]}) {types[eventType].title}
                      <br />
                    </li>
                  ))
                : null}
            </ul>
          </Col>
          <Col span={18}>
            <Timeline mode="left">
              {data.map((item) => {
                if (timelineFilter && timelineFilter !== item.event_type) {
                  return null;
                }

                if (
                  matchSecondFilter &&
                  matchSecondFilter * 1 < item.match_second
                ) {
                  return null;
                }

                return (
                  <Item
                    dot="*"
                    style={{ cursor: "pointer" }}
                    onClick={() => onClick(item)}
                    key={item.id}
                    label={renderEvenLeftSideLabel(item)}
                  >
                    <Popover
                      content={
                        <div style={{ width: 400 }}>
                          <Map readonly x={item.pos_x} y={item.pos_y} />
                        </div>
                      }
                      placement="right"
                    >
                      <img
                        style={{ height: "20px" }}
                        src={getIconComponentFromEventTypeId(item.event_type)}
                        alt=""
                      />
                      &nbsp;
                      {item.text}
                    </Popover>
                  </Item>
                );
              })}
            </Timeline>
          </Col>
        </Row>
        {isEventModalVisible ? (
          <Modal
            title="Edit event"
            visible={isEventModalVisible}
            footer={false}
            onCancel={() => setIsEventModalVisible(false)}
            width="95%"
            style={{
              top: 20,
            }}
          >
            <Event
              id={eventCommntary.id}
              eventTypeId={chosenEvent.id}
              area=""
              xCoord={eventCommntary.pos_x}
              yCoord={eventCommntary.pos_y}
              matchData={matchData}
              onSave={() => setIsEventModalVisible(false)}
              onDelete={() => setIsEventModalVisible(false)}
              onCancel={() => setIsEventModalVisible(false)}
              eventCommentaryData={eventCommntary}
              isChainedEvent={false}
            />
          </Modal>
        ) : null}
      </>
    );
  }

  return <div>No commentary found for this match</div>;
}

export default MatchTimeline;

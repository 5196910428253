import { useState } from "react";
import { useQuery } from "react-query";
import EventChoice from "./EventChoice";
import MatchTimeline from "./MatchTimeline";
import Score from "./Score";
import Map from "./Map";
import Clock from "./Clock";
import { getMatchesSingle } from "./../api/matches";
import { getGridSector } from "./../api/stadium";
import { getSuggestedEventTypes } from "./../api/events";

function Report({ matchId }) {
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const [clearTimelineFilter, setClearTimelineFilter] = useState(true);
  const [showAllEvents, setShowAllEvents] = useState(false);

  const loadMatchData = async () => {
    const result = await getMatchesSingle(matchId);

    const rawData = result.data.data;

    if (rawData.lineups.team_1.length && rawData.lineups.team_2.length) {
      if (rawData.lineups.team_1[0].team_id === rawData.home_id) {
        rawData.lineups["home"] = rawData.lineups.team_1;
        rawData.lineups["away"] = rawData.lineups.team_2;
      } else {
        rawData.lineups["home"] = rawData.lineups.team_2;
        rawData.lineups["away"] = rawData.lineups.team_1;
      }
    }

    if (!localStorage.getItem("match.left")) {
      localStorage.setItem(
        "match.left",
        JSON.stringify({
          teamId: rawData.home_id,
          teamName: rawData.home_name,
        })
      );
      localStorage.setItem(
        "match.right",
        JSON.stringify({
          teamId: rawData.away_id,
          teamName: rawData.away_name,
        })
      );
    }

    return rawData;
  };

  const getGridSectorFromCoordinates = async () => {
    const result = await getGridSector(x, y);
    return result.data.data.area;
  };

  const { data: area } = useQuery(
    ["gridSector", x, y],
    getGridSectorFromCoordinates,
    {
      staleTime: 24 * 60 * 60 * 1000,
    }
  );

  const getSuggestedEventTypesFromCoordinates = async () => {
    if (x && y) {
      const result = await getSuggestedEventTypes(x, y);
      return result.data.data;
    }

    return [];
  };

  const { data: suggested } = useQuery(
    ["sugggestedEventTypes", x, y],
    getSuggestedEventTypesFromCoordinates,
    {
      staleTime: 24 * 60 * 60 * 1000,
    }
  );

  const { data: matchData, isLoading: isMatchLoading } = useQuery(
    ["matchSingle", matchId],
    loadMatchData,
    {
      staleTime: 60 * 1000,
    }
  );

  const onLocationChoice = (x, y) => {
    setX(x);
    setY(y);
  };

  const onShowAllEvents = () => {
    setShowAllEvents(!showAllEvents);
  };

  const onEventSaved = (eventTypeId) => {
    // Since these events are followed by an event from pretty
    // much the same location we do not want to clear their
    // coordinates so we can reuse them and save people clicks and time
    if (
      ["FOUL_COMMITTED", "OFFSIDE", "THROW_IN_AWARDED"].indexOf(eventTypeId) ===
      -1
    ) {
      setX(0);
      setY(0);
    }

    setClearTimelineFilter(true);
  };

  if (matchData) {
    if (isMatchLoading) {
      return <div>Loading...</div>;
    } else {
      if (
        matchData.lineups.team_1.length === 0 &&
        matchData.lineups.team_2.length === 0
      ) {
        return (
          <div>
            The lineups of the match are incomplete please fix them first
          </div>
        );
      }

      return (
        <div className="App">
          <table>
            <tbody>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          X({x}) Y({y}) Area({area})
                        </td>
                        <td>
                          <Clock matchId={matchId} />
                        </td>
                        <td style={{ textAlign: "right", width: "33%" }}>
                          ID: {matchId} &nbsp;
                          {matchData.time}'{matchData.home_name}&nbsp;
                          <Score matchId={matchId} />
                          &nbsp;
                          {matchData.away_name}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={3}>
                          <table style={{ width: "100%" }}>
                            <tbody>
                              <tr>
                                <td style={{ width: "25%" }}>
                                  HT: {matchData.ht_score}
                                </td>
                                <td style={{ width: "25%" }}>
                                  FT: {matchData.ft_score}
                                </td>
                                <td style={{ width: "25%" }}>
                                  ET: {matchData.et_score}
                                </td>
                                <td style={{ width: "25%" }}>
                                  PS: {matchData.ps_score}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td rowSpan={3} style={{ width: "40%", verticalAlign: "top" }}>
                  <EventChoice
                    onEventSaved={onEventSaved}
                    area={area}
                    x={x}
                    y={y}
                    matchId={matchId}
                    matchData={matchData}
                    suggested={suggested}
                    showAllEvents={showAllEvents}
                  />
                </td>
              </tr>
              <tr>
                <td style={{ witdh: "60%", verticalAlign: "top" }}>
                  <Map
                    onShowAllEvents={onShowAllEvents}
                    onLocationChoice={onLocationChoice}
                    x={x}
                    y={y}
                  />
                  <hr />
                  <MatchTimeline
                    matchId={matchId}
                    matchData={matchData}
                    clearTimelineFilter={clearTimelineFilter}
                    onFilter={() => setClearTimelineFilter(false)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    }
  } else {
    return (
      <div>
        {matchId ? (
          <>Chosen match id: {matchId}</>
        ) : (
          <>Please choose a match!</>
        )}
      </div>
    );
  }
}

export default Report;

import { Button } from "antd";
import Col from "react-bootstrap/Col";
import { getIconComponentFromEventTypeId } from "./Icon";

const EventChoiceButton = ({
  event,
  onClick,
  hidden,
  suggested,
  style,
  imageStyle,
}) => {
  let defaultStyle = {
    width: "100px",
    height: "70px",
    textAlign: "center",
    padding: 2,
    overflow: "hidden",
  };

  if (suggested) {
    defaultStyle["backgroundColor"] = "#a2fba2";
  }

  if (hidden) {
    return null;
  }

  if (!event) {
    return null;
  }

  return (
    <Col md={2} key={event.id}>
      <Button
        style={{ ...defaultStyle, ...style }}
        onClick={() => onClick(event)}
        title={event.title}
      >
        <img
          src={getIconComponentFromEventTypeId(event.id)}
          alt={event.title}
          style={{ width: "40px", height: "40px", ...imageStyle }}
        />
        <small>
          <br />
          {event.title}
        </small>
      </Button>
    </Col>
  );
};

export default EventChoiceButton;

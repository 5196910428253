import React, { useState, useEffect } from "react";
import { useQuery, QueryClient } from "react-query";
import { Modal } from "antd";

import Event from "./Event";
import EventChoiceButton from "./EventChoiceButton";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button, Divider, Tabs } from "antd";

import { getEventTypes, getMatchCommentaryTimeline } from "./../api/events";
import Substitutions from "./substitutions/Substitutions";
import { chainedEvents } from "./../util/events";
import { useParams } from "react-router-dom";

const queryClient = new QueryClient();

const EventChoice = ({
  area,
  x,
  y,
  onEventSaved,
  matchData,
  suggested,
  showAllEvents,
}) => {
  const [isSubstitutionsModalVisible, setIsSubstitutionsModalVisible] =
    useState(false);
  const [isEventModalVisible, setIsEventModalVisible] = useState(false);
  const [isChainedEventsModalVisible, setIsChainedEventsModalVisible] =
    useState(false);
  const [isChainedEvent, setIsChainedEvent] = useState(false);
  const [chainedEventsTypeIds, setChainedEventsTypeIds] = useState([]);
  const [chosenEvent, setChosenEvent] = useState({});

  const { data: types } = useQuery("types", getEventTypes, {
    refetchOnWindowFocus: false,
  });

  let { commentaryEventId } = useParams();
  useEffect(() => {
    if (types && commentaryEventId) {
      for (let key in types) {
        if (key == commentaryEventId) {
          showModal(types[key]);
          setIsEventModalVisible(true);
        }
      }
    }
  }, [commentaryEventId, types]);

  const getMatchTimeline = async () => {
    const result = await getMatchCommentaryTimeline(matchData.id);
    return result.data.data;
  };

  const { data: timeline } = useQuery(
    ["timeline", matchData["id"]],
    getMatchTimeline,
    {
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );

  let occurred = [];
  if (timeline) {
    occurred = timeline.map((item) => item.event_type);
  }

  const showModal = (item) => {
    setChosenEvent(item);
    setIsEventModalVisible(true);
  };

  const showSubstitutionsModal = (item) => {
    setIsSubstitutionsModalVisible(true);
  };

  const saveEvent = (eventTypeId) => {
    setIsEventModalVisible(false);
    onEventSaved(eventTypeId);

    if (eventTypeId.includes("_SHOOTOUT_")) {
      queryClient.invalidateQueries(["matchSingle", matchData.id]);
    }

    if (chainedEvents.hasOwnProperty(eventTypeId)) {
      setIsChainedEventsModalVisible(true);
      setChainedEventsTypeIds(chainedEvents[eventTypeId]);
      setIsChainedEvent(true);
    } else {
      setIsChainedEvent(false);
    }
  };

  const isSuggested = (eventType) => {
    return (
      suggested?.indexOf(eventType) !== -1 &&
      suggested?.indexOf(eventType) !== undefined
    );
  };

  return (
    <>
      {types && (types.length > 0 || Object.keys(types).length > 0) ? (
        <>
          <Row>
            <EventChoiceButton
              event={types["KICK_OFF"]}
              onClick={showModal}
              suggested={isSuggested("KICK_OFF")}
            />
            <EventChoiceButton
              event={types["MATCH_START"]}
              onClick={showModal}
              suggested={isSuggested("MATCH_START") || timeline?.length === 0}
              hidden={occurred.indexOf("MATCH_START") !== -1}
            />
            <EventChoiceButton
              event={types["FIRST_HALF_OVER"]}
              onClick={showModal}
              suggested={isSuggested("FIRST_HALF_OVER")}
              hidden={
                (occurred.indexOf("MATCH_START") === -1 ||
                  occurred.indexOf("FIRST_HALF_OVER") !== -1) &&
                !showAllEvents
              }
            />
            <EventChoiceButton
              event={types["SECOND_HALF_START"]}
              onClick={showModal}
              suggested={isSuggested("SECOND_HALF_START")}
              hidden={
                (occurred.indexOf("FIRST_HALF_OVER") === -1 ||
                  occurred.indexOf("SECOND_HALF_START") !== -1) &&
                !showAllEvents
              }
            />
            <EventChoiceButton
              event={types["SECOND_HALF_OVER"]}
              onClick={showModal}
              suggested={isSuggested("SECOND_HALF_OVER")}
              hidden={
                (occurred.indexOf("SECOND_HALF_START") === -1 ||
                  occurred.indexOf("SECOND_HALF_OVER") !== -1) &&
                !showAllEvents
              }
            />
            <EventChoiceButton
              event={types["EXTRA_TIME_START"]}
              onClick={showModal}
              suggested={isSuggested("EXTRA_TIME_START")}
              hidden={
                (occurred.indexOf("SECOND_HALF_OVER") === -1 ||
                  occurred.indexOf("EXTRA_TIME_START") !== -1) &&
                !showAllEvents
              }
            />
            <EventChoiceButton
              event={types["EXTRA_TIME_HALF_TIME"]}
              onClick={showModal}
              suggested={isSuggested("EXTRA_TIME_HALF_TIME")}
              hidden={
                (occurred.indexOf("EXTRA_TIME_START") === -1 ||
                  occurred.indexOf("EXTRA_TIME_HALF_TIME") !== -1) &&
                !showAllEvents
              }
            />
            <EventChoiceButton
              event={types["EXTRA_TIME_SECOND_HALF_START"]}
              onClick={showModal}
              suggested={isSuggested("EXTRA_TIME_SECOND_HALF_START")}
              hidden={
                (occurred.indexOf("EXTRA_TIME_HALF_TIME") === -1 ||
                  occurred.indexOf("EXTRA_TIME_SECOND_HALF_START") !== -1) &&
                !showAllEvents
              }
            />
            <EventChoiceButton
              event={types["EXTRA_TIME_OVER"]}
              onClick={showModal}
              suggested={isSuggested("EXTRA_TIME_OVER")}
              hidden={
                occurred.indexOf("EXTRA_TIME_OVER") === -1 && !showAllEvents
              }
            />
            <EventChoiceButton
              event={types["PENALTY_SHOOTOUT_START"]}
              onClick={showModal}
              suggested={isSuggested("PENALTY_SHOOTOUT_START")}
              disabled={occurred.indexOf("PENALTY_SHOOTOUT_START") !== -1}
              hidden={
                (occurred.indexOf("EXTRA_TIME_SECOND_HALF_START") === -1 ||
                  occurred.indexOf("PENALTY_SHOOTOUT_START") !== -1) &&
                !showAllEvents
              }
            />
            <EventChoiceButton
              event={types["PENALTY_SHOOTOUT_END"]}
              onClick={showModal}
              suggested={isSuggested("PENALTY_SHOOTOUT_END")}
              disabled={occurred.indexOf("PENALTY_SHOOTOUT_END") !== -1}
              hidden={
                (occurred.indexOf("PENALTY_SHOOTOUT_START") === -1 ||
                  occurred.indexOf("PENALTY_SHOOTOUT_END") !== -1) &&
                !showAllEvents
              }
            />

            <EventChoiceButton
              event={types["WATER_BREAK"]}
              onClick={showModal}
              suggested={isSuggested("WATER_BREAK")}
            />
            <EventChoiceButton
              event={types["SUBSTITUTION"]}
              onClick={showSubstitutionsModal}
              suggested={isSuggested("SUBSTITUTION")}
            />
            <EventChoiceButton
              event={types["ADDITIONAL_TIME"]}
              onClick={showModal}
              suggested={isSuggested("ADDITIONAL_TIME")}
            />
            <EventChoiceButton
              event={types["MATCH_ENDS"]}
              onClick={showModal}
              suggested={isSuggested("MATCH_ENDS")}
              disabled={occurred.indexOf("MATCH_ENDS") !== -1}
            />
            <EventChoiceButton
              event={types["WINNER"]}
              onClick={showModal}
              suggested={isSuggested("WINNER")}
              disabled={occurred.indexOf("WINNER") !== -1}
            />
            <EventChoiceButton
              event={types["DRAW"]}
              onClick={showModal}
              suggested={isSuggested("DRAW")}
              disabled={occurred.indexOf("DRAW") !== -1}
            />
            <EventChoiceButton
              event={types["QUALIFIES_NEXT_ROUND"]}
              onClick={showModal}
              suggested={isSuggested("QUALIFIES_NEXT_ROUND")}
            />
          </Row>
          <Tabs>
            <Tabs.TabPane tab="In play" key="1">
              <Row>
                <EventChoiceButton
                  event={types["FOUL_COMMITTED"]}
                  onClick={showModal}
                  suggested={isSuggested("FOUL_COMMITTED")}
                />
                <EventChoiceButton
                  event={types["FREE_KICK_TAKEN"]}
                  onClick={showModal}
                  suggested={isSuggested("FREE_KICK_TAKEN")}
                />
                <EventChoiceButton
                  event={types["OFFSIDE"]}
                  onClick={showModal}
                  suggested={isSuggested("OFFSIDE")}
                />
                <hr />
                <EventChoiceButton
                  event={types["PLAYER_WARNED"]}
                  onClick={showModal}
                  suggested={isSuggested("PLAYER_WARNED")}
                />
                <EventChoiceButton
                  event={types["YELLOW_CARD"]}
                  onClick={showModal}
                  suggested={isSuggested("YELLOW_CARD")}
                />
                <EventChoiceButton
                  event={types["YELLOW_CARD_CANCELED"]}
                  onClick={showModal}
                  suggested={isSuggested("YELLOW_CARD_CANCELED")}
                />
                <EventChoiceButton
                  event={types["YELLOW_RED_CARD"]}
                  onClick={showModal}
                  suggested={isSuggested("YELLOW_RED_CARD")}
                />
                <EventChoiceButton
                  event={types["RED_CARD"]}
                  onClick={showModal}
                  suggested={isSuggested("RED_CARD")}
                />
                <EventChoiceButton
                  event={types["RED_CARD_CANCELED"]}
                  onClick={showModal}
                  suggested={isSuggested("RED_CARD_CANCELED")}
                />
                <hr />
                <EventChoiceButton
                  event={types["CORNER_KICK_AWARDED"]}
                  onClick={showModal}
                  suggested={isSuggested("CORNER_KICK_AWARDED")}
                />
                <EventChoiceButton
                  event={types["CORNER_KICK_TAKEN"]}
                  onClick={showModal}
                  suggested={isSuggested("CORNER_KICK_TAKEN")}
                />
                <EventChoiceButton
                  event={types["THROW_IN_AWARDED"]}
                  onClick={showModal}
                  suggested={isSuggested("THROW_IN_AWARDED")}
                />
                <EventChoiceButton
                  event={types["THROW_IN_TAKEN"]}
                  onClick={showModal}
                  suggested={isSuggested("THROW_IN_TAKEN")}
                />
                <EventChoiceButton
                  event={types["GOAL_KICK"]}
                  onClick={showModal}
                  suggested={isSuggested("GOAL_KICK")}
                />
                <EventChoiceButton
                  event={types["GOAL_KICK_TAKEN"]}
                  onClick={showModal}
                  suggested={isSuggested("GOAL_KICK_TAKEN")}
                />
                <hr />
                <EventChoiceButton
                  event={types["SHOT_ON_TARGET"]}
                  onClick={showModal}
                  suggested={isSuggested("SHOT_ON_TARGET")}
                />
                <EventChoiceButton
                  event={types["SHOT_OFF_TARGET"]}
                  onClick={showModal}
                  suggested={isSuggested("SHOT_OFF_TARGET")}
                />
                <EventChoiceButton
                  event={types["SHOT_BLOCKED"]}
                  onClick={showModal}
                  suggested={isSuggested("SHOT_BLOCKED")}
                />
                <EventChoiceButton
                  event={types["GOALKEEPER_SAVE"]}
                  onClick={showModal}
                  suggested={isSuggested("GOALKEEPER_SAVE")}
                />
                <EventChoiceButton
                  event={types["CROSSBAR_HIT"]}
                  onClick={showModal}
                  suggested={isSuggested("CROSSBAR_HIT")}
                />
                <EventChoiceButton
                  event={types["POST_HIT"]}
                  onClick={showModal}
                  suggested={isSuggested("POST_HIT")}
                />
                <hr />
                <EventChoiceButton
                  event={types["GOAL"]}
                  onClick={showModal}
                  suggested={isSuggested("GOAL")}
                />
                <EventChoiceButton
                  event={types["PENALTY_AWARDED"]}
                  onClick={showModal}
                  suggested={isSuggested("PENALTY_AWARDED")}
                />
                <EventChoiceButton
                  event={types["GOAL_PENALTY"]}
                  onClick={showModal}
                  suggested={isSuggested("GOAL_PENALTY")}
                />
                <EventChoiceButton
                  event={types["MISSED_PENALTY"]}
                  onClick={showModal}
                  suggested={isSuggested("MISSED_PENALTY")}
                />
                <EventChoiceButton
                  event={types["OWN_GOAL"]}
                  onClick={showModal}
                  suggested={isSuggested("OWN_GOAL")}
                />
                <hr />
                <EventChoiceButton
                  event={types["TREATMENT"]}
                  onClick={showModal}
                  suggested={isSuggested("TREATMENT")}
                />
                <EventChoiceButton
                  event={types["MATCH_STOPPED_INJURY"]}
                  onClick={showModal}
                  suggested={isSuggested("MATCH_STOPPED_INJURY")}
                />
                {/* <Col md={2}>
                    <EventChoiceButton
                      event={types["SUBSTITUTION"]}
                      onClick={showModal}
                      suggested={isSuggested("SUBSTITUTION")}
                    />
                  </Col> */}
                <EventChoiceButton
                  event={types["CONTAINS_BALL"]}
                  onClick={showModal}
                  suggested={isSuggested("CONTAINS_BALL")}
                />
                <EventChoiceButton
                  event={types["WASTING_TIME"]}
                  onClick={showModal}
                  suggested={isSuggested("WASTING_TIME")}
                />
                <hr />
                <EventChoiceButton
                  event={types["VAR_RED_CARD_REVIEW"]}
                  onClick={showModal}
                  suggested={isSuggested("VAR_RED_CARD_REVIEW")}
                />
                <EventChoiceButton
                  event={types["VAR_PENALTY_REVIEW"]}
                  onClick={showModal}
                  suggested={isSuggested("VAR_PENALTY_REVIEW")}
                />
                <EventChoiceButton
                  event={types["VAR_GOAL_REVIEW"]}
                  onClick={showModal}
                  suggested={isSuggested("VAR_GOAL_REVIEW")}
                />
                <EventChoiceButton
                  event={types["VAR_REVIEW_OVER"]}
                  onClick={showModal}
                  suggested={isSuggested("VAR_REVIEW_OVER")}
                />
                <hr />
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Penalty shootout" key="2">
              <Row>
                <EventChoiceButton
                  event={types["PENALTY_SHOOTOUT_TAKER"]}
                  onClick={showModal}
                  suggested={isSuggested("PENALTY_SHOOTOUT_TAKER")}
                />
                <EventChoiceButton
                  event={types["PENALTY_SHOOTOUT_GOAL"]}
                  onClick={showModal}
                  suggested={isSuggested("PENALTY_SHOOTOUT_GOAL")}
                />
                <EventChoiceButton
                  event={types["PENALTY_SHOOTOUT_MISS"]}
                  onClick={showModal}
                  suggested={isSuggested("PENALTY_SHOOTOUT_MISS")}
                />
                <EventChoiceButton
                  event={types["PENALTY_SHOOTOUT_SAVED"]}
                  onClick={showModal}
                  suggested={isSuggested("PENALTY_SHOOTOUT_SAVED")}
                />
              </Row>
            </Tabs.TabPane>
          </Tabs>
        </>
      ) : null}
      {isEventModalVisible ? (
        <Modal
          title="Event create"
          visible={isEventModalVisible}
          footer={false}
          onCancel={() => {
            setIsChainedEvent(false);
            setIsEventModalVisible(false);
          }}
          width="95%"
          style={{
            top: 20,
          }}
        >
          <Event
            id={null}
            eventTypeId={chosenEvent.id}
            area={area}
            xCoord={x}
            yCoord={y}
            matchData={matchData}
            onSave={saveEvent}
            onCancel={() => {
              setIsEventModalVisible(false);
              setIsChainedEvent(false);
            }}
            eventCommentaryData={null}
            isChainedEvent={isChainedEvent}
          />
        </Modal>
      ) : null}

      {isChainedEventsModalVisible ? (
        <Modal
          title="Event create"
          visible={isChainedEventsModalVisible}
          footer={false}
          onCancel={() => {
            setIsChainedEventsModalVisible(false);
            setIsChainedEvent(false);
          }}
          width="95%"
          style={{
            top: 20,
          }}
        >
          <Row>
            {chainedEventsTypeIds.map((item) => (
              <Col md={2} key={item}>
                <EventChoiceButton
                  event={types[item]}
                  onClick={(event) => {
                    setIsChainedEventsModalVisible(false);
                    setIsChainedEvent(true);
                    showModal(event);
                  }}
                  suggested={false}
                  style={{ height: 200, width: 200 }}
                  imageStyle={{ width: 100, height: 100 }}
                />
              </Col>
            ))}
          </Row>
          <Divider />
          <div style={{ textAlign: "right" }}>
            <Button
              onClick={() => {
                setIsChainedEventsModalVisible(false);
                setIsChainedEvent(false);
              }}
              size="small"
            >
              Cancel
            </Button>
          </div>
        </Modal>
      ) : null}

      {isSubstitutionsModalVisible ? (
        <Modal
          title="Substitutions"
          visible={isSubstitutionsModalVisible}
          footer={false}
          onCancel={() => setIsSubstitutionsModalVisible(false)}
          width="95%"
          style={{
            top: 20,
          }}
        >
          <Substitutions matchData={matchData} />
        </Modal>
      ) : null}
    </>
  );
};

export default EventChoice;

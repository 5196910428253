import qs from "qs";
import axiosClient from "./axiosClient";

const getSingleEventType = async (eventTypeId) => {
  return await axiosClient.get(`/events/type?event_type_id=${eventTypeId}`);
};

const getEventTypes = async (x, y) => {
  const result = await axiosClient.get("/commentary/types");

  const reindexed = {};
  result.data.data.forEach((eventType) => {
    reindexed[eventType.id] = eventType;
  });

  return reindexed;
};

const saveCommentarySingle = async (newCommentary) => {
  if (newCommentary.x >= 100) {
    newCommentary.x = 99;
  }

  if (newCommentary.y >= 100) {
    newCommentary.y = 99;
  }

  await axiosClient.post("/commentary/single", newCommentary);
};

const deleteCommentarySingle = async (eventCommentaryId) => {
  await axiosClient.delete("/commentary/single", {
    headers: { "content-type": "application/x-www-form-urlencoded" },
    data: qs.stringify({ event_commentary_id: eventCommentaryId }),
  });
};

const getMatchCommentaryTimeline = async (matchId) => {
  return await axiosClient.get(`/commentary/timeline?match_id=${matchId}`);
};

const getMatchClock = async (matchId) => {
  return await axiosClient.get(`/commentary/clock?match_id=${matchId}`);
};

const getMatchClockFromMatchSecond = async (matchId, matchSecond) => {
  return await axiosClient.get(
    `/commentary/clock?match_id=${matchId}&match_second=${matchSecond}`
  );
};

const getSuggestedEventTypes = async (posX, posY) => {
  return await axiosClient.get(
    `/commentary/suggest?pos_x=${posX}&pos_y=${posY}`
  );
};

const getPreviousMatches = async () => {
  return await axiosClient.get("/commentary/previous");
};

export {
  getEventTypes,
  saveCommentarySingle,
  getMatchCommentaryTimeline,
  getMatchClock,
  getMatchClockFromMatchSecond,
  getSingleEventType,
  deleteCommentarySingle,
  getSuggestedEventTypes,
  getPreviousMatches,
};

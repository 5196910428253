import { getTeamLogoUrl } from "../util/teams";

const TeamLogo = ({ teamId, style }) => {
  const defaultStyle = { height: "1.5rem" };
  let styles = { ...defaultStyle, ...style };

  if (!teamId) {
    return null;
  }

  return <img style={styles} alt="Team logo" src={getTeamLogoUrl(teamId)} />;
};

export default TeamLogo;

import React, { useState, useCallback, useEffect, useMemo } from "react";
import { Modal, Select, Input, Button, AutoComplete } from "antd";
import { SearchOutlined, CloseOutlined } from "@ant-design/icons";
import { useQuery } from "react-query";
import { getAllCountries } from "../api/countries";
import { searchCompetitions } from "../api/competitions";

const getCountries = () =>
  getAllCountries({
    "no-skip": true,
    "real-only": true,
  });

const CompetitionChoice = ({ onCancel, onCompetitionChoice }) => {
  const { data: countriesData, isLoading: isCountriesLoading } = useQuery({
    queryKey: ["getAllCountries"],
    queryFn: getCountries,
    staleTime: Infinity,
  });

  const [competitionId, setCompetitionId] = useState(null);
  const [countryName, setCountryName] = useState("");
  const [countryId, setCountryId] = useState();
  const [searchParams, setSearchParams] = useState({});
  const [search, setSearch] = useState("");
  const onChangeKey = useCallback((event) => setSearch(event.target.value), []);

  const onChangeCountryName = useCallback(
    (name, { label, value } = {}) => {
      setCountryName(label || name);
      const countryId = value ? Number.parseInt(value, 10) : undefined;
      setCountryId(countryId);
      setCompetitionId(undefined);
      setSearchParams({
        search,
        country_id: countryId,
      });
    },
    [setCountryName, setCountryId, search]
  );

  const onClearSearch = useCallback(() => {
    setSearch("");
    setCompetitionId(undefined);
    setCountryId();
    setCountryName();
    setSearchParams({
      search,
      country_id: countryId,
    });
  }, [countryId]);

  const onSearch = useCallback(() => {
    setCompetitionId(undefined);
    setSearchParams({
      search,
      country_id: countryId,
    });
  }, [search, countryId]);

  const onKeyPress = useCallback(
    (event) => {
      if (event.key === "Enter") {
        onSearch();
      }
    },
    [onSearch]
  );

  const countryOptions = useMemo(
    () =>
      countriesData?.data.data.map(({ id, name }) => ({
        label: name,
        value: String(id),
      })),
    [countriesData]
  );

  const compareFilterString = (inputValue, option) =>
    String(option?.label || "")
      .toLocaleLowerCase()
      .includes(String(inputValue || "").toLocaleLowerCase());

  const fetchCompetitions = async ({ queryKey }) => {
    return await searchCompetitions(queryKey[1]);
  };

  const {
    data: searchCompetitionsResponse,
    isLoading: isLoadingSearchCompetitions,
  } = useQuery({
    queryKey: ["searchCompetitions", searchParams],
    queryFn: fetchCompetitions,
    staleTime: Infinity,
  });

  const sortSearchCompetitionOptions = (data, countryId) =>
    data
      .map((competition) => {
        const locationPrefix = competition.countries.length
          ? competition.countries[0].name
          : competition.federations.length
          ? competition.federations[0].name
          : "";
        const competitionAndTier = `${competition.name} || (Tier: ${competition.tier})`;
        const compositeName = `${locationPrefix} || ${competitionAndTier}`;

        return {
          ...competition,
          name: countryId ? competitionAndTier : compositeName,
        };
      })
      .sort((competition1, competition2) =>
        competition1.name.localeCompare(competition2.name)
      );

  const searchCompetitionOptions = useMemo(
    () =>
      sortSearchCompetitionOptions(
        searchCompetitionsResponse?.data.data || [],
        countryId || 0
      ),
    [searchCompetitionsResponse, countryId]
  );

  useEffect(() => {
    if (searchCompetitionOptions?.length === 1) {
      setCompetitionId(searchCompetitionOptions[0].id);
    }
  }, [searchCompetitionOptions]);

  const onOk = () => {
    if (competitionId) {
      onCompetitionChoice(competitionId);
    } else {
      // TODO: add error message
      Modal.error({
        title: "Error",
        content: "Please choose a competition first",
        centered: true,
      });
    }
  };

  return (
    <Modal
      title="Choose a competition to work with"
      visible={true}
      onCancel={onCancel}
      onOk={onOk}
    >
      <div>
        <span>Search country:</span>
        <div style={{ width: "100%", marginBottom: "10px" }}>
          <AutoComplete
            aria-label="country"
            value={countryName}
            onChange={onChangeCountryName}
            options={countryOptions}
            placeholder="Type a Country"
            filterOption={compareFilterString}
            disabled={isCountriesLoading}
            backfill
            style={{ width: "100%" }}
          />
        </div>

        <span>Search competition:</span>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "80% 1fr 1fr",
            alignItems: "center",
            gap: "10px",
            marginBottom: "20px",
          }}
        >
          <Input
            aria-label="search competition"
            value={search}
            onChange={onChangeKey}
            placeholder="Type a Competition"
            onKeyUp={onKeyPress}
            disabled={isLoadingSearchCompetitions}
            autoComplete="new-search"
          />
          <Button
            type="primary"
            icon={<SearchOutlined />}
            onClick={onSearch}
            loading={isLoadingSearchCompetitions}
          />
          <Button
            onClick={onClearSearch}
            icon={<CloseOutlined />}
            danger
          ></Button>
        </div>
      </div>

      <Select
        aria-label="competition"
        className="w-full"
        value={competitionId}
        onChange={setCompetitionId}
        disabled={isLoadingSearchCompetitions}
        style={{ width: "100%" }}
      >
        {searchCompetitionOptions?.map?.((competition) => (
          <Select.Option key={competition.id} value={competition.id}>
            {competition.name}
          </Select.Option>
        ))}
      </Select>
    </Modal>
  );
};

export default CompetitionChoice;

import { useState, useEffect } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Input } from "antd";
import { getMatchScore, saveMatchScore } from "./../api/matches";

function Score({ matchId }) {
  const [score, setScore] = useState("? - ?");

  const queryClient = useQueryClient();

  const getScore = async () => {
    const result = await getMatchScore(matchId);
    return result.data.data;
  };

  const { data } = useQuery(["score", matchId], getScore, {
    staleTime: 60 * 1000,
  });

  useEffect(() => {
    setScore(data);
  }, [data]);

  const saveScoreMutation = useMutation(async (payload) => {
    return await saveMatchScore(payload);
  });

  const onPressEnter = () => {
    const payload = {
      match_id: matchId,
      score: score,
    };

    saveScoreMutation.mutate(payload);
    queryClient.invalidateQueries("match-clock");
    queryClient.invalidateQueries(["score", matchId]);
  };

  return (
    <Input
      style={{ width: "70px" }}
      placeholder="Home goals"
      value={score}
      onChange={(e) => setScore(e.target.value)}
      onPressEnter={onPressEnter}
    />
  );
}

export default Score;

import React, { useState } from "react";
import { Button, Form, Input, notification } from "antd";
import { useMutation } from "react-query";
import { userLogin } from "../api/users";
import { useNavigate } from "react-router-dom";

const Login = ({ onLogin }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false); // State to manage loading state

  function redirectToDashboard() {
    navigate("/");
  }

  const doLogin = useMutation(
    async (values) => {
      const loginResult = await userLogin(values);
      return loginResult.data.data;
    },
    {
      onError(err, variables, onMutateValue) {
        notification["error"]({
          message: "Could not login",
          description: err.response.data.error,
          placement: "bottomLeft",
        });
        setLoading(false); // Set loading to false on error
      },
      onSuccess(data, variables, contect) {
        localStorage.setItem("authToken", data.token);
        localStorage.setItem("adminId", data.id);
        notification["success"]({
          message: "Login successfull",
          description: "Login successfull",
          placement: "bottomLeft",
        });
        setLoading(false); // Set loading to false on success
        onLogin(true);
        redirectToDashboard();
      },
      onMutate: () => {
        setLoading(true); // Set loading to true when login process starts
      },
    }
  );

  const onFinish = (values) => {
    doLogin.mutate(values);
  };

  return (
    <Form
      name="basic"
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      initialValues={{
        remember: true,
      }}
      autoComplete="off"
      onFinish={onFinish}
    >
      <Form.Item label="Username" name="username">
        <Input />
      </Form.Item>

      <Form.Item label="Password" name="password">
        <Input.Password />
      </Form.Item>

      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Button
          type="primary"
          htmlType="submit"
          loading={loading} // Show loader inside button while logging in
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Login;

import { Col, Row, Typography } from "antd";
import TeamLogo from "../TeamLogo";
import TeamSubstitutions from "./TeamSubstitutions";

const { Title } = Typography;

const Substitutions = ({ matchData }) => {
  return (
    <>
      <Row>
        <Col md={12}>
          <Title level={1}>
            <TeamLogo teamId={matchData.home_id} style={{ height: "3rem" }} />
            {matchData.home_name}
          </Title>
          <TeamSubstitutions
            matchData={matchData}
            teamId={matchData.home_id}
            teamLneup={matchData.lineups.home}
          />
        </Col>
        <Col md={12}>
          <Title level={1}>
            <TeamLogo teamId={matchData.away_id} style={{ height: "3rem" }} />
            {matchData.away_name}
          </Title>
          <TeamSubstitutions
            matchData={matchData}
            teamId={matchData.away_id}
            teamLneup={matchData.lineups.away}
          />
        </Col>
      </Row>
    </>
  );
};

export default Substitutions;

import logo from "../img/soccer_field.png";
import ball from "../img/soccer_ball.png";
import { useState, useEffect } from "react";
import { Button } from "antd";
import useMeasure from "react-use-measure";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import TeamLogo from "./TeamLogo";

function Map({ onShowAllEvents, onLocationChoice, x, y, readonly }) {
  const [ref, bounds] = useMeasure();
  const [xPixels, setXPixels] = useState(-100);
  const [yPixels, setYPixels] = useState(-100);
  const [leftTeam, setLeftTeam] = useState({});
  const [rightTeam, setRigthTeam] = useState({});

  const onClick = (e) => {
    var bounds = e.target.getBoundingClientRect();

    // We need the -10 so that when you click the mouse cursor
    // appears in the center of the ball
    const clickX = e.clientX - bounds.left - 9;
    const clickY = e.clientY - bounds.top - 9;

    setXPixels(clickX);
    setYPixels(clickY);

    // We need to add 10 so that the event coordinates are as the user intended
    // which is where the cursor was clicked and we -10 in order for the ball
    // icon to appear around the cursor
    const clickPercentageX = Math.round(
      ((clickX * 1) / bounds.width) * 100 + 1
    );
    const clickPercentageY = Math.round(
      ((clickY * 1) / bounds.height) * 100 + 1
    );

    onLocationChoice(clickPercentageX, clickPercentageY);
  };

  useEffect(() => {
    if (!x && !y) {
      setXPixels(-100);
      setYPixels(-100);
    }
  }, [x, y]);

  useEffect(() => {
    if (x && y) {
      setXPixels(x);
      setYPixels(y);
    }

    // We need to substract 10 like we did in the on click function
    setXPixels((bounds.width * x) / 100 - 10);
    setYPixels((bounds.height * y) / 100 - 10);
  }, [bounds]);

  useEffect(() => {
    loadSides();
    const interval = setInterval(loadSides, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const loadSides = () => {
    setLeftTeam(JSON.parse(localStorage.getItem("match.left")));
    setRigthTeam(JSON.parse(localStorage.getItem("match.right")));
  };

  const resetCoordinates = () => {
    setXPixels(0);
    setYPixels(0);
    onLocationChoice(0, 0);
  };

  const switchSides = () => {
    const left = localStorage.getItem("match.left");
    const right = localStorage.getItem("match.right");
    localStorage.setItem("match.left", right);
    localStorage.setItem("match.right", left);
    setLeftTeam(JSON.parse(right));
    setRigthTeam(JSON.parse(left));
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          position: "relative",
          top: 0,
          left: 0,
          verticalAlign: "top",
        }}
      >
        <img
          ref={ref}
          style={{ width: "100%", position: "relative", top: 0, left: 0 }}
          src={logo}
          alt="logo"
          onClick={onClick}
        />
        <img
          style={{
            position: "absolute",
            top: yPixels + "px",
            left: xPixels + "px",
            width: "20px",
          }}
          src={ball}
          alt="ball"
        />
      </div>
      {!readonly ? (
        <>
          <br />
          {leftTeam ? (
            <Row>
              <Col lg={5} style={{ textAlign: "right" }}>
                ({leftTeam.teamId})&nbsp;{leftTeam.teamName}
                <TeamLogo teamId={leftTeam.teamId} />
              </Col>
              <Col lg={2} style={{ textAlign: "center" }}>
                <Button onClick={switchSides}>&#x21CB;</Button>
              </Col>
              <Col lg={5} style={{ textAlign: "left" }}>
                <TeamLogo teamId={rightTeam.teamId} />
                {rightTeam.teamName}&nbsp;({rightTeam.teamId})
              </Col>
            </Row>
          ) : null}
          <br />
          <Button onClick={resetCoordinates}>Reset coordinates</Button>{" "}
          <Button onClick={onShowAllEvents}>Toggle all events</Button>
        </>
      ) : null}
    </>
  );
}

export default Map;

import {
  Alert,
  Badge,
  Button,
  Card,
  Divider,
  Input,
  Select,
  Tag,
  notification,
} from "antd";
import { useState, useEffect } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { DisplayMatchClock } from "./Clock";
import Map from "./Map";
import {
  getEventTypes,
  saveCommentarySingle,
  getSingleEventType,
  getMatchClock,
  deleteCommentarySingle,
  getMatchCommentaryTimeline,
  getMatchClockFromMatchSecond,
} from "./../api/events";
import { getIconComponentFromEventTypeId } from "./Icon";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { getTeamLogoUrl } from "../util/teams";
import { getAdminSettings } from "../api/users";

const { Option } = Select;

const SUBSTITUTION_EVENT_TYPE_ID = "SUBSTITUTION";

const adjustPlayersOnTheField = (lineup, inPlayer, outPlayer) => {
  return lineup
    .map((item) => {
      if (item.id === inPlayer) {
        item.substitution = false;
      }

      if (item.id === outPlayer) {
        item.substitution = true;
      }
      return item;
    })
    .sort((a, b) => (a.shirt_number > b.shirt_number ? 1 : -1));
};

const Event = ({
  eventTypeId,
  matchData,
  onSave,
  onCancel,
  onDelete,
  id,
  eventCommentaryData,
  xCoord,
  yCoord,
}) => {
  const [chosenEventTypeId, setChosenEventTypeId] = useState("");
  const [playerId, setPlayerId] = useState(null);
  const [player2Id, setPlayer2Id] = useState(null);
  const [teamId, setTeamId] = useState(null);
  const [side, setSide] = useState("");
  const [comment, setComment] = useState("");
  const [nextPlayerToChoose, setNextPlayerToChoose] = useState("player1");
  const [matchClock, setMatchClock] = useState({});
  const [showSubs, setShowSubs] = useState(false);

  let homeLineup = matchData.lineups.home;
  let awayLineup = matchData.lineups.away;

  const [xLocation, setXLocation] = useState(0);
  const [yLocation, setYLocation] = useState(0);

  const queryClient = useQueryClient();

  const sortGoalkeepersOnTop = (a) =>
    a.position.toUpperCase() === "GK" ? -1 : 0;

  const updateMatchClock = async () => {
    const result = await getMatchClock(matchData.id);
    setMatchClock(result.data.data);
  };

  const chainEventsTrigger = async () => {
    await updateMatchClock();

    // If we are adding a new event we chain it to the lastest
    // event but if we are chaining an event that we are editing
    // we chain to the previous event in the timeline
    if (id) {
      for (let i = 0; i < timeline.length; ++i) {
        if (timeline[i]["id"] == id && i < timeline.length - 1) {
          console.log(timeline[i + 1]);
          setMatchClock({
            minute: timeline[i + 1]["minute"],
            second: timeline[i + 1]["second"],
            match_second: timeline[i + 1]["match_second"] * 1 + 1,
          });
        }
      }
    } else {
      setMatchClock({
        minute: localStorage.getItem("matchClock.minute"),
        second: localStorage.getItem("matchClock.second"),
        match_second: matchClock.match_second,
      });
    }
  };

  const getMatchSecondsFromSettings = async () => {
    const settings = await getAdminSettings();
    for (let i = 0; i < settings.length; ++i) {
      const property = settings[i].key;
      if (property == "commentary-match-second") {
        const clockFromMatchSecond = await getMatchClockFromMatchSecond(
          matchData.id,
          settings[i].value
        );
        setMatchClock(clockFromMatchSecond.data.data);
      }
    }
  };

  // Sets the x, y location of the event from the props
  useEffect(() => {
    setXLocation(xCoord);
    setYLocation(yCoord);
  }, [xCoord, yCoord]);

  // Setting the location of the user choice on the map
  // or when we need to set default location or when
  // we use the default coordinates for events
  const onLocationChoice = (x, y) => {
    setXLocation(x);
    setYLocation(y);
  };

  // If we are not loading an existing commentary event
  // we use the current match clock otherwise we will
  // store the match clock data from the loaded event
  useEffect(() => {
    if (!eventCommentaryData) {
      const getMatchClockData = async () => {
        const result = await getMatchClock(matchData.id);
        setMatchClock(result.data.data);
      };
      getMatchClockData();
    }
  }, [matchData.id, eventCommentaryData]);

  useEffect(() => {
    if (eventCommentaryData) {
      setPlayerId(eventCommentaryData.player_id);
      setPlayer2Id(eventCommentaryData.player2_id);
      setTeamId(eventCommentaryData.team_id);
      setSide(eventCommentaryData.side);
      setComment(eventCommentaryData.comment);
      setMatchClock({
        minute: eventCommentaryData.minute,
        second: eventCommentaryData.second,
        match_second: eventCommentaryData.match_second,
      });
    }
  }, [eventCommentaryData]);

  // If the event type if changes we perform some actions to setup default values for them
  useEffect(() => {
    setChosenEventTypeId(eventTypeId);
    if (
      [
        "KICK_OFF",
        "MATCH_START",
        "SECOND_HALF_START",
        "EXTRA_TIME_START",
        "EXTRA_TIME_SECOND_HALF_START",
      ].indexOf(eventTypeId) !== -1
    ) {
      onLocationChoice(50, 50);
    }
  }, [eventTypeId]);

  // If we are doing a substitution event we show the subs by default
  // otherwise they are hidden from the user
  useEffect(() => {
    if (chosenEventTypeId === SUBSTITUTION_EVENT_TYPE_ID) {
      setShowSubs(true);
    }

    // We want to perform this only for new events and we do not want
    // to change old ones
    if (xCoord && yCoord && !eventCommentaryData?.id) {
      const left = JSON.parse(localStorage.getItem("match.left"));
      const right = JSON.parse(localStorage.getItem("match.right"));

      if (eventTypeId === "GOALKEEPER_SAVE") {
        let teamSquad = [];
        if (xCoord < 50) {
          onTeamChoice(left.teamId);
          teamSquad =
            left.teamId * 1 === matchData.home_id ? homeLineup : awayLineup;
        } else {
          onTeamChoice(right.teamId);
          teamSquad =
            right.teamId * 1 === matchData.home_id ? homeLineup : awayLineup;
        }

        const selectedGoalkeeper = teamSquad.filter(
          (p) => !p.substitution && p.position.toUpperCase() === "GK"
        );
        if (selectedGoalkeeper.length) {
          setPlayerId(selectedGoalkeeper[0].id);
        }
      }

      // If these events happen based on the location of the ball we can decide
      // which team to set as the beneficiary of the event
      if (
        ["GOAL_KICK", "GOAL_KICK_TAKEN", "SHOT_BLOCKED"].indexOf(
          eventTypeId
        ) !== -1
      ) {
        if (xCoord < 50) {
          onTeamChoice(left.teamId);
        } else {
          onTeamChoice(right.teamId);
        }
      }

      if (
        [
          "CORNER_KICK_AWARDED",
          "CORNER_KICK_TAKEN",
          "CROSSBAR_HIT",
          "GOAL",
          "GOAL_PENALTY",
          "MISSED_PENALTY",
          "OFFSIDE",
          "OWN_GOAL",
          "PENALTY_AWARDED",
          "POST_HIT",
          "SHOT_OFF_TARGET",
          "SHOT_ON_TARGET",
          "PENALTY_AWARDED",
        ].indexOf(eventTypeId) !== -1
      ) {
        if (xCoord < 50) {
          onTeamChoice(right.teamId);
        } else {
          onTeamChoice(left.teamId);
        }
      }
    }
  }, [chosenEventTypeId]);

  const getMatchTimeline = async () => {
    const result = await getMatchCommentaryTimeline(matchData.id);
    return result.data.data;
  };

  const { data: timeline } = useQuery(
    ["timeline", matchData.id],
    getMatchTimeline,
    {
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );

  // If there are events in the timeline and there have been substitutions
  // we will show them in the squad on the left that is currently on the pitch
  // all people that have been taken off will be moved to the subs section on the right
  if (timeline) {
    const substitutions = [];
    timeline.forEach((item) => {
      if (item.event_type === SUBSTITUTION_EVENT_TYPE_ID) {
        substitutions.unshift(item);
      }
    });

    if (substitutions) {
      substitutions.forEach((item) => {
        const inPlayer = item.player_id;
        const outPlayer = item.player2_id;

        homeLineup = adjustPlayersOnTheField(homeLineup, inPlayer, outPlayer);
        awayLineup = adjustPlayersOnTheField(awayLineup, inPlayer, outPlayer);
      });
    }
  }

  const save = () => {
    saveEvent();
    onSave(chosenEventTypeId);
  };

  const getEventTypeData = async () => {
    const result = await getSingleEventType(chosenEventTypeId);
    return result.data.data;
  };

  const { data: event } = useQuery(
    ["single-type", chosenEventTypeId],
    getEventTypeData,
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );

  const { data: types } = useQuery("types", getEventTypes, {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  const deleteCommentary = useMutation(
    async (eventCommentaryId) => {
      return await deleteCommentarySingle(eventCommentaryId);
    },
    {
      onError(err, variables, onMutateValue) {
        notification["error"]({
          message: "Error while deleting match event",
          description: err.response.data.error,
          placement: "bottomLeft",
        });
      },
      onSuccess(data, variables, contect) {
        notification["success"]({
          message: "Commentary deleted",
          description: "Match event commentary successfully deleted",
          placement: "bottomLeft",
        });

        queryClient.invalidateQueries(["timeline", matchData.id]);
      },
    }
  );

  const deleteEvent = () => {
    if (window.confirm("Please confirm event deletion") === true) {
      deleteCommentary.mutate(eventCommentaryData.id);
      onDelete();
    }
  };

  const saveCommentary = useMutation(
    async (newCommentary) => {
      localStorage.setItem("matchClock.minute", newCommentary.minute);
      localStorage.setItem("matchClock.second", newCommentary.second);
      return await saveCommentarySingle(newCommentary);
    },
    {
      onError(err, variables, onMutateValue) {
        notification["error"]({
          message: "Error while saving match events",
          description: err.response.data.error,
          placement: "bottomLeft",
        });
      },
      onSuccess(data, variables, contect) {
        notification["success"]({
          message: "Commentary saved",
          description: "Match event commentary successfully saved",
          placement: "bottomLeft",
        });

        queryClient.invalidateQueries(["timeline", matchData.id]);
        queryClient.invalidateQueries("match-clock");
        queryClient.invalidateQueries(["score", matchData.id]);
      },
    }
  );

  const saveEvent = () => {
    const payload = {
      id: id,
      match_id: matchData.id,
      event_type: chosenEventTypeId,
      team_id: teamId,
      player_id: playerId,
      player2_id: player2Id,
      minute: matchClock.minute,
      second: matchClock.second,
      match_second: matchClock.match_second,
      comment: comment,
      x: xLocation,
      y: yLocation,
      side: side,
    };

    saveCommentary.mutate(payload);
  };

  const onPlayerChoice = (chosenPlayerId) => {
    if (playerId && playerId === chosenPlayerId) {
      setPlayerId(null);
      setNextPlayerToChoose("player1");
      return;
    }

    if (player2Id && player2Id === chosenPlayerId) {
      setPlayer2Id(null);
      if (playerId) {
        setNextPlayerToChoose("player2");
      } else {
        setNextPlayerToChoose("player1");
      }
      return;
    }

    if (nextPlayerToChoose === "player1") {
      setPlayerId(chosenPlayerId);
      if (event.player_2) {
        setNextPlayerToChoose("player2");
      }

      // If there is a team as part of the event data we automatically
      // select that teаm when we choose a player
      if (event.team) {
        let found = false;
        for (let i = 0; i < homeLineup.length; ++i) {
          if (chosenPlayerId === homeLineup[i].id) {
            setTeamId(homeLineup[i].team_id);
            setSide("h");
            found = true;
            break;
          }
        }
        if (!found) {
          setSide("a");
          setTeamId(awayLineup[0].team_id);
        }
      }
    } else {
      setPlayer2Id(chosenPlayerId);
      setNextPlayerToChoose("player1");
    }
  };

  const getPlayerLabel = (number) => {
    switch (event.id) {
      case "FOUL_COMMITTED":
        return number === 1 ? "Violator" : "Fouled";
      case "GOAL":
        return number === 1 ? "Scorer" : "Assist";
      case "PENALTY_SHOOTOUT_SAVED":
      case "PENALTY_SHOOTOUT_TAKER":
      case "PENALTY_SHOOTOUT_MISS":
      case "PENALTY_SHOOTOUT_GOAL":
        return "Taker";
      case "SHOT_BLOCKED":
        return number === 1 ? "Blocker" : "Taker";
      case "SUBSTITUTION":
        return number === 1 ? "In" : "Out";
      default:
        return "Chosen";
    }
  };

  const renderPlayerChoice = (playerData) => {
    return (
      <Button
        key={playerData.id}
        type="text"
        onClick={() => {
          onPlayerChoice(playerData.id);
        }}
      >
        {playerData.shirt_number}.&nbsp;{playerData.name}
        {playerData.position.toUpperCase() === "GK" ? <b> (GK)</b> : null}&nbsp;
        {playerId === playerData.id ? (
          <Tag color="processing">{getPlayerLabel(1)}</Tag>
        ) : null}
        {player2Id === playerData.id ? (
          <Tag color="processing">{getPlayerLabel(2)}</Tag>
        ) : null}
      </Button>
    );
  };

  const onTeamChoice = (teamId) => {
    setTeamId(teamId);
    if (teamId * 1 === matchData.home_id * 1) {
      setSide("h");
    } else {
      setSide("a");
    }
  };

  const getSelectOptions = () => {
    let result = [];
    for (const _type in types) {
      result.push(
        <Option key={types[_type].id} value={types[_type].id}>
          {types[_type].title}
        </Option>
      );
    }
    return result;
  };

  if (event) {
    var errorsCount =
      (event.coordinates && !xLocation && !yLocation) +
      (event.team && !teamId) +
      (event.player && !playerId) +
      (event.player_2 && !player2Id);
    return (
      <Row>
        <Col md={6}>
          {event.coordinates ? (
            <Map
              onLocationChoice={onLocationChoice}
              x={xLocation}
              y={yLocation}
            />
          ) : null}
        </Col>
        <Col md={6}>
          <Row>
            <Col md={3}>
              {event.coordinates && !xLocation && !yLocation ? (
                <Alert message="Missing coordinates" type="warning" />
              ) : null}
            </Col>
            <Col md={3}>
              {event.team && !teamId ? (
                <Alert message="Missing team" type="warning" />
              ) : null}
            </Col>
            <Col md={3}>
              {event.player && !playerId ? (
                <Alert message="Missing player" type="warning" />
              ) : null}
            </Col>
            <Col md={3}>
              {event.player_2 && !player2Id ? (
                <Alert message="Missing second player" type="warning" />
              ) : null}
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={3}>
              <img
                src={getIconComponentFromEventTypeId(event.id)}
                alt={event.title}
                style={{ width: "40px", height: "40px" }}
              />
              &nbsp;
              {event.title}
            </Col>
            <Col md={2}>
              at ({xLocation}, {yLocation})
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 4, offset: 2 }}>
              <DisplayMatchClock
                minute={matchClock.minute}
                second={matchClock.second}
                matchSecond={matchClock.match_second}
                onChange={(minute, second, matchSecond) => {
                  console.log(minute, second, matchSecond);
                  setMatchClock({
                    minute,
                    second,
                    match_second: matchSecond,
                  });
                }}
              />
            </Col>
            <Col md={2}>
              <Button onClick={updateMatchClock}>Refresh clock</Button>
            </Col>
            <Col md={2}>
              <Button onClick={getMatchSecondsFromSettings}>From Video</Button>
            </Col>
            <Col md={2}>
              <Button onClick={chainEventsTrigger}>Chain events</Button>
            </Col>
          </Row>
          <br />
          <Row>
            {types ? (
              <Col md={6}>
                <Select
                  style={{ width: "100%" }}
                  defaultValue={event.id}
                  onChange={setChosenEventTypeId}
                >
                  {getSelectOptions()}
                </Select>
              </Col>
            ) : null}
            <Col md={6}>
              <Input
                placeholder="Comment"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            </Col>
          </Row>
          {event.team ? (
            <div>
              {matchData.home_id === teamId ? (
                <Tag key="home" color="processing">
                  {matchData.home_name}
                </Tag>
              ) : (
                <Button
                  key="home"
                  type="text"
                  onClick={() => onTeamChoice(matchData.home_id)}
                >
                  {matchData.home_name}
                </Button>
              )}
              vs&nbsp;
              {matchData.away_id === teamId ? (
                <Tag key="away" color="processing">
                  {matchData.away_name}
                </Tag>
              ) : (
                <Button
                  key="away"
                  type="text"
                  onClick={() => onTeamChoice(matchData.away_id)}
                >
                  {matchData.away_name}
                </Button>
              )}
              Side: {side}
            </div>
          ) : null}
          {event.player ? (
            <table>
              <tbody>
                <tr>
                  <td>
                    <Card title={matchData.home_name}>
                      <table
                        style={{
                          backgroundImage: `url(${getTeamLogoUrl(
                            matchData.home_id
                          )})`,
                          backgroundPosition: "-50% -50%",
                          backgroundRepeat: "no-repeat",
                        }}
                      >
                        <tbody
                          style={{
                            backgroundColor: "rgba(255, 255, 255, 0.9)",
                          }}
                        >
                          <tr>
                            <td style={{ verticalAlign: "top" }}>
                              {homeLineup
                                .sort(sortGoalkeepersOnTop)
                                .map((lineupPlayer, index) => {
                                  if (lineupPlayer.substitution) {
                                    return null;
                                  }
                                  return (
                                    <span key={lineupPlayer.id.toString()}>
                                      {renderPlayerChoice(lineupPlayer)}
                                      <br />
                                    </span>
                                  );
                                })}
                            </td>
                            {showSubs ? (
                              <td style={{ verticalAlign: "top" }}>
                                {homeLineup
                                  .sort(sortGoalkeepersOnTop)
                                  .map((lineupPlayer, index) => {
                                    if (!lineupPlayer.substitution) {
                                      return null;
                                    }
                                    return (
                                      <span key={lineupPlayer.id.toString()}>
                                        {renderPlayerChoice(lineupPlayer)}
                                        <br />
                                      </span>
                                    );
                                  })}
                              </td>
                            ) : (
                              <td
                                style={{ width: "175px", verticalAlign: "top" }}
                              >
                                <Button onClick={() => setShowSubs(!showSubs)}>
                                  Show subs
                                </Button>
                              </td>
                            )}
                          </tr>
                        </tbody>
                      </table>
                    </Card>
                  </td>
                  <td>
                    <Card title={matchData.away_name}>
                      <table
                        style={{
                          backgroundImage: `url(${getTeamLogoUrl(
                            matchData.away_id
                          )})`,
                          backgroundPosition: "-50% -50%",
                          backgroundRepeat: "no-repeat",
                        }}
                      >
                        <tbody
                          style={{
                            backgroundColor: "rgba(255, 255, 255, 0.9)",
                          }}
                        >
                          <tr>
                            <td style={{ verticalAlign: "top" }}>
                              {awayLineup
                                .sort(sortGoalkeepersOnTop)
                                .map((lineupPlayer, index) => {
                                  if (lineupPlayer.substitution) {
                                    return null;
                                  }
                                  return (
                                    <span key={lineupPlayer.id.toString()}>
                                      {renderPlayerChoice(lineupPlayer)}
                                      <br />
                                    </span>
                                  );
                                })}
                            </td>
                            {showSubs ? (
                              <td style={{ verticalAlign: "top" }}>
                                {awayLineup
                                  .sort(sortGoalkeepersOnTop)
                                  .map((lineupPlayer, index) => {
                                    if (!lineupPlayer.substitution) {
                                      return null;
                                    }
                                    return (
                                      <span key={lineupPlayer.id.toString()}>
                                        {renderPlayerChoice(lineupPlayer)}
                                        <br />
                                      </span>
                                    );
                                  })}
                              </td>
                            ) : (
                              <td
                                style={{ width: "175px", verticalAlign: "top" }}
                              >
                                <Button onClick={() => setShowSubs(!showSubs)}>
                                  Show subs
                                </Button>
                              </td>
                            )}
                          </tr>
                        </tbody>
                      </table>
                    </Card>
                  </td>
                </tr>
              </tbody>
            </table>
          ) : null}

          <Divider />
          <div style={{ textAlign: "right" }}>
            {eventCommentaryData ? (
              <Button type="danger" onClick={deleteEvent} size="small">
                Delete
              </Button>
            ) : null}
            &nbsp;
            <Button onClick={onCancel} size="small">
              Cancel
            </Button>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Badge count={errorsCount}>
              <Button type="primary" onClick={save} size="large">
                Save
              </Button>
            </Badge>
          </div>
        </Col>
      </Row>
    );
  } else {
    return null;
  }
};

export default Event;

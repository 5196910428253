import { useEffect, useState } from "react";
import { Button, Row, Col, Input } from "antd";

const PlayerSubstitution = ({
  sub,
  teamLneup,
  onSave,
  onDelete,
  isNew,
  index,
}) => {
  const [isEnqueued, setIsEnqeueued] = useState(true);
  const [playerIn, setPlayerIn] = useState({});
  const [playerOut, setPlayerOut] = useState({});

  const [playerInShirtNumberValue, setPlayerInShirtNumberValue] = useState("");
  const [playerOutShirtNumberValue, setPlayerOutShirtNumberValue] =
    useState("");

  useEffect(() => {
    if (sub) {
      setPlayerIn(sub.player);
      setPlayerInShirtNumberValue(sub.player?.shirt_number);
      setPlayerOut(sub.player2);
      setPlayerOutShirtNumberValue(sub.player2?.shirt_number);
    }
  }, [sub]);

  const changePlayer = (shirtNumber, callback) => {
    setIsEnqeueued(false);
    for (let i = 0; i < teamLneup.length; i++) {
      if (teamLneup[i].shirt_number * 1 === shirtNumber * 1) {
        callback(teamLneup[i]);
        return;
      }
    }

    callback("");
  };

  const onAddHandler = () => {
    setIsEnqeueued(true);
    onSave({
      player: playerIn,
      player2: playerOut,
      index: index,
    });
  };

  const onDeleteHandle = () => {
    onDelete(sub.id);
  };

  return (
    <Row>
      <Col md={8}>
        <Input
          disabled={!isNew}
          addonAfter={playerIn?.name}
          value={playerInShirtNumberValue}
          onChange={(e) => {
            setPlayerInShirtNumberValue(e.target.value);
          }}
          onBlur={(e) => {
            changePlayer(e.target.value, setPlayerIn);
          }}
        />
      </Col>
      <Col md={1}></Col>
      <Col md={8}>
        <Input
          disabled={!isNew}
          addonAfter={playerOut?.name}
          value={playerOutShirtNumberValue}
          onChange={(e) => {
            setPlayerOutShirtNumberValue(e.target.value);
          }}
          onBlur={(e) => {
            changePlayer(e.target.value, setPlayerOut);
          }}
        />
      </Col>
      <Col md={1}></Col>
      <Col md={6}>
        {!isNew ? (
          <Button type="danger" onClick={onDeleteHandle} size="small">
            Delete
          </Button>
        ) : null}

        {isNew && !isEnqueued ? (
          <Button type="success" onClick={onAddHandler} size="small">
            Add
          </Button>
        ) : null}
      </Col>
    </Row>
  );
};

export default PlayerSubstitution;

import axiosClient from "./axiosClient";

const userLogin = async ({ username, password }) => {
  return await axiosClient.post("/admin/login", {
    username,
    password,
    system: "commentary",
  });
};

const getAdminSettings = async () => {
  const response = await axiosClient.get(
    "/admin/settings?admin_id=" + localStorage.getItem("adminId")
  );
  return response.data.data;
};

export { userLogin, getAdminSettings };

import { Layout } from "antd";
import Report from "./Report";
import { useEffect } from "react";

const { Content } = Layout;

function MatchReport({ matchId }) {
  useEffect(() => {
    localStorage.removeItem("match.left");
    localStorage.removeItem("match.right");
  }, []);

  useEffect(() => {
    localStorage.removeItem("match.left");
    localStorage.removeItem("match.right");
  }, [matchId]);

  return matchId ? (
    <Content style={{ backgroundColor: "white" }}>
      <Report matchId={matchId} />
    </Content>
  ) : (
    <div>You need to choose a match to report commentary on</div>
  );
}

export default MatchReport;

import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  getMatchCommentaryTimeline,
  getMatchClock,
  saveCommentarySingle,
  deleteCommentarySingle,
} from "./../../api/events";
import PlayerSubstitution from "./PlayerSubstitution";
import { Col, Row, notification, Button, Space } from "antd";
import { useState } from "react";

const initialQueue = [
  { index: 0 },
  { index: 1 },
  { index: 2 },
  { index: 3 },
  { index: 4 },
];

const TeamSubstitutions = ({ matchData, teamId, teamLneup }) => {
  const [queue, setQueue] = useState(initialQueue);
  const queryClient = useQueryClient();

  const getMatchTimeline = async () => {
    const result = await getMatchCommentaryTimeline(matchData.id);
    return result.data.data;
  };

  const { data: timeline } = useQuery(
    ["timeline", matchData.id],
    getMatchTimeline,
    {
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );

  const teamSubs = timeline.filter(
    (item) => item.team_id === teamId && item.event_type === "SUBSTITUTION"
  );

  const enrichedTeamSubs = teamSubs.map((item) => {
    let enriched = item;

    for (let i = 0; i < teamLneup.length; ++i) {
      if (teamLneup[i].id * 1 === item.player_id * 1) {
        enriched["player"] = teamLneup[i];
      }

      if (teamLneup[i].id * 1 === item.player2_id * 1) {
        enriched["player2"] = teamLneup[i];
      }
    }

    return enriched;
  });

  const saveCommentary = useMutation(
    async (newCommentary) => {
      localStorage.setItem("matchClock.minute", newCommentary.minute);
      localStorage.setItem("matchClock.second", newCommentary.second);
      return await saveCommentarySingle(newCommentary);
    },
    {
      onError(err, variables, onMutateValue) {
        notification["error"]({
          message: "Error while saving match events",
          description: err.response.data.error,
          placement: "bottomLeft",
        });
      },
      onSuccess(data, variables, contect) {
        notification["success"]({
          message: "Commentary saved",
          description: "Match event commentary successfully saved",
          placement: "bottomLeft",
        });

        queryClient.invalidateQueries(["timeline", matchData.id]);
        queryClient.invalidateQueries("match-clock");
        setQueue(initialQueue);
      },
    }
  );

  const onMasterSave = async () => {
    let players = [];
    let players2 = [];

    const response = await getMatchClock(matchData.id);
    const matchClock = response.data.data;

    queue.map((item) => {
      if (item.player_id || item.player2_id) {
        players.push(item.player_id ? item.player_id : null);
        players2.push(item.player2_id ? item.player2_id : null);
      }

      return null;
    });

    let payload = {
      id: null,
      match_id: matchData.id,
      event_type: "SUBSTITUTION",
      team_id: teamId,
      player_id: players,
      player2_id: players2,
      minute: matchClock.minute,
      second: matchClock.second,
      match_second: matchClock.match_second,
      comment: "",
      x: 0,
      y: 0,
      side: matchData.home_id === teamId ? "h" : "a",
    };

    saveCommentary.mutate(payload);
  };

  const deleteCommentary = useMutation(
    async (eventCommentaryId) => {
      return await deleteCommentarySingle(eventCommentaryId);
    },
    {
      onError(err, variables, onMutateValue) {
        notification["error"]({
          message: "Error while deleting match event",
          description: err.response.data.error,
          placement: "bottomLeft",
        });
      },
      onSuccess(data, variables, contect) {
        notification["success"]({
          message: "Commentary deleted",
          description: "Match event commentary successfully deleted",
          placement: "bottomLeft",
        });

        queryClient.invalidateQueries(["timeline", matchData.id]);
      },
    }
  );

  const deleteEvent = (eventId) => {
    if (window.confirm("Please confirm event deletion") === true) {
      deleteCommentary.mutate(eventId);
    }
  };

  const onEnqueue = (newData) => {
    const newQueue = [
      ...queue.filter((item) => item.index !== newData.index),
      {
        player_id: newData.player?.id,
        player: newData.player,
        player2: newData.player2,
        player2_id: newData.player2?.id,
        index: newData.index,
      },
    ];

    setQueue(newQueue);
  };

  const sortedQueue = queue.sort((a, b) => (a.index > b.index ? 1 : -1));

  let pending = 0;
  for (let i = 0; i < sortedQueue.length; ++i) {
    if (sortedQueue[i].player_id || sortedQueue[i].player2_id) {
      pending++;
    }
  }

  return (
    <>
      {enrichedTeamSubs.map((item) => (
        <Row key={item.id}>
          <Col md={24}>
            <PlayerSubstitution
              sub={item}
              teamLneup={teamLneup}
              onDelete={deleteEvent}
              isNew={false}
            />
          </Col>
        </Row>
      ))}

      <hr />
      <Row key={-1}>
        <Col md={9}>
          <b>In</b>
        </Col>
        <Col md={8}>
          <b>Out</b>
        </Col>
      </Row>
      {[0, 1, 2, 3, 4].map((index) => (
        <Row key={index}>
          <Col md={24}>
            <PlayerSubstitution
              sub={sortedQueue[index]}
              teamLneup={teamLneup}
              onSave={onEnqueue}
              index={index}
              isNew={true}
            />
          </Col>
        </Row>
      ))}
      <br />
      <Space>
        <Button
          type="primary"
          size="large"
          onClick={onMasterSave}
          disabled={pending === 0}
        >
          Save
        </Button>
        {pending} substitutions waiting to be saved
      </Space>
    </>
  );
};

export default TeamSubstitutions;
